import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQAnalyticUntHldList } from "./redux/actions";
import DateInput from "./DateInput";
import "./style.scss";
function AnalyticUntHldList(props) {
  const {
    REQAnalyticUntHldList,
    REQStrAnalyticUntHldList,
    AnalyticUntHldList,
    history
  } = props;

  const [tStartD, SETxStartD] = useState(new Date());
  const [tEndD, SETxEndD] = useState(new Date());
  const [bAllD, SETbAllD] = useState(false);
  console.log("Entered LIST")
  console.log('tStartD:',tStartD)
  console.log('history:',history.location.UISearch)
  const CALLxAnalyticUntHld = () => {
    if(history.location.UISearch===undefined)
    {
      REQAnalyticUntHldList({
        tStartD,
        tEndD,
        bAllD
      });
    }
    else
    {
      SETxStartD(new Date(history.location.UISearch.tStartD));
      SETxEndD(new Date(history.location.UISearch.tEndD));

      SETbAllD(history.location.UISearch.bAllD);

      REQAnalyticUntHldList({
        tStartD:history.location.UISearch.tStartD,
        tEndD:history.location.UISearch.tEndD,
        bAllD:history.location.UISearch.bAllD
      });

    
    }

    };

  const SRCHxAnalyticAct = () => {
    REQAnalyticUntHldList({
      tStartD,
      tEndD,
      bAllD
    });
  };

  const SETxChangeHighlight = () => {
    REQAnalyticUntHldList({
      tStartD,
      tEndD,
      bAllD:!bAllD
    });

    SETbAllD(!bAllD);
  };

  const SETxActTableClick=(ptAccStatusCodeType)=>{
    history.push({ pathname: '/AnalyticAppsDetail',AccStatusCodeType:ptAccStatusCodeType,
      UISearch:{
        tStartD:tStartD,
        tEndD:tEndD,
        bAllD:bAllD
      }
    });
};
  const CALxTableAnalyUntHld = (poData) => {
    let oData = poData.data;
    console.log("oData", oData)
    return (
      <div className="usrAct-list-container">
        <h3 style={{
          textAlign:"center",
          color:"#000000",
          fontWeight:"bold",
          fontFamily:"Lato"
      }}
        >Applicant</h3>
        <div className="acti-feed-form-input-spacer" />
        <table className="usrAct-table" role="table">
          <thead className="usrAct-head-container">
          <tr role="row" className="usrAct-head-row">
            <th colSpan={1} role="columnheader" className="usrAct-header" style={{width:100,paddingLeft:10}}>Number of apps</th>
            <th colSpan={1}  role="columnheader" className="usrAct-header" style={{width:100,paddingLeft:10}}>Approved</th>
            <th colSpan={1}  role="columnheader" className="usrAct-header" style={{width:100,paddingLeft:10}}>Pending</th>
            
          </tr>
          </thead>
          <tbody className="usrAct-table-body">
          <tr role="row" className="usrAct-table-row">
            <td role="cell" className="usrAct-cell" style={{width: 100,paddingLeft: 10,textAlign:"left"}}>
              {
                oData.UnitholderTotal===0?
                oData.UnitholderTotal:
                <button className="feed-list-article-button" onClick={() => SETxActTableClick("Total")}>{oData.UnitholderTotal}</button>
              }  
            </td>
            <td role="cell" className="usrAct-cell" style={{width: 100,paddingLeft: 10,textAlign:"left"}}>
                {
                  oData.UnitholderApprove===0?
                  oData.UnitholderApprove:
                  <button className="feed-list-article-button" onClick={() => SETxActTableClick("Approve")}>{oData.UnitholderApprove}</button>
                }
            </td>
            <td role="cell" className="usrAct-cell" style={{width: 100,paddingLeft: 10,textAlign:"left"}}>
                {
                  oData.UnitholderPending===0?
                  oData.UnitholderPending:
                  <button className="feed-list-article-button" onClick={() => SETxActTableClick("Pending")}>{oData.UnitholderPending}</button>
                }
            </td>
          </tr>
        </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    CALLxAnalyticUntHld();
    },[]);
    
  return (
    <Fragment>
    <div className="button-create-container" style={{ marginBottom: 1 }}>
      <div style={{ height: 40 }} />
      {REQStrAnalyticUntHldList ? (
        <div className="regisDevice-list-progress">
          <CircularProgress />
          <p className="regisDevice-list-progress-text">
            {REQStrAnalyticUntHldList ? "Retrieving Analytic Unitholder list..." : null}
          </p>
        </div>
      ) : null}
    </div>


    <div className="date-range-container" style={{ width: 400,marginTop:50 }}>
        <p className="labelInputText" style={{ width: 290 }}>
          Date
          <span style={{ color: "#FF0000" }}>*</span>
        </p>
        <p style={{ paddingLeft:65,paddingRight: 10 }}>:</p>

        <DatePicker
          selected={tStartD}
          onChange={date => SETxStartD(date)}
          selectsStart
          startDate={tStartD}
          endDate={tEndD}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
          className={bAllD===false?"date-range-input":"date-range-input-disable"}
          disabled={bAllD}
        
        />
        <p className="date-range-separator">-</p>
        {
          <DatePicker
          selected={tEndD}
          onChange={date => moment(date).isAfter(tStartD) && SETxEndD(date)}
          selectsEnd
          startDate={tStartD}
          endDate={tEndD}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
          className={bAllD===false?"date-range-input":"date-range-input-disable"}
          disabled={bAllD}
        />
        }
        <div className="labelInput" style={{ height: 40, marginLeft: 20 }}>
          <input style={{ width: 18, height: 18, marginRight: 10 }}
                type="checkbox"
                id="Highlight"
                name="Highlight"
                checked={bAllD}
                onChange={SETxChangeHighlight}
              />
          <span className="labelInputText ">Show all date</span>
        </div>
      </div>


    <button
        style={{ marginLeft: 20,marginBottom:20 }}
        onClick={() => {
          SRCHxAnalyticAct();
        }}
        className="conFd-synce-button"
      >
        Search
    </button>
    {}
    {REQStrAnalyticUntHldList ? null : (
      <CALxTableAnalyUntHld
        data={AnalyticUntHldList}
      />
    )}


  </Fragment>
  );
}

 
const withConnect = connect(
  state => ({
    ...state.analyticListReducer
  }),
  {
    REQAnalyticUntHldList
  }
);

export default compose(
  withConnect,
  withRouter
)(AnalyticUntHldList);
