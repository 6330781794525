import React, { Fragment,useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter  } from "react-router-dom";
import ReactJson from 'react-json-view';
import "./style.scss";
function RegisLogDetail(poPrp) {
  const {
    RowDataAt,
    history,
    PageCurrent,
    RegisLogType,
    UISearch
  } = poPrp;

  useEffect(() => {
    return () => {
       if (history.action === "POP") {
         if (RegisLogType==="counter_service") {
          history.replace({
            pathname:'/RegisLog_CounterService',
            PageCurrent: PageCurrent,
            RegisLogType:RegisLogType,
            UISearch:UISearch
          });
         } else if (RegisLogType==="ndid") {
          history.replace({
            pathname:'/RegisLog_NDID',
            PageCurrent: PageCurrent,
            RegisLogType:RegisLogType,
            UISearch:UISearch
          });
         }
       }
    };

 }, []);
  const SHWxImgCounterServiceID = ()=>{
    let tImg1=null;
    try {
      // ---------picture_identify-------------------
      if (RowDataAt.DipchipClient===null) {
        console.log("SHWxImgCounterServiceID >>> RowDataAt.DipchipClient===null");
      }
      else if(RowDataAt.DipchipClient[0].picture_identify.length===0) {
        console.log("SHWxImgCounterServiceID >>> RowDataAt.DipchipClient[0].picture_identify.length===0");
      }
      else {
        tImg1=RowDataAt.DipchipClient[0].picture_identify;
        return (
          <div style={{marginLeft:10,marginRight:10}}>
            <img width="110px" height="110px"
                src={tImg1 === "" || tImg1 === null ? "/static/media/avatar-2.cd29f3645ffb7d82bfa8.png" : "data:image/png;base64," + tImg1}
            />
            <div>identify</div>
          </div>
          );
      }
    }
    catch(ex) {
      console.log("SHWxImgCounterServiceID >>> catch", ex);
    }
  };
  const SHWxImgCounterServiceCard = ()=>{
    let tImg1=null;
    try{
    // ---------picture_card-------------------
    if(RowDataAt.DipchipClient===null) {
      console.log("SHWxImgCounterServiceCard >>> RowDataAt.DipchipClient===null");
    }
    else if(RowDataAt.DipchipClient[0].picture_card.length===0) {
      console.log("SHWxImgCounterServiceCard >>> RowDataAt.DipchipClient[0].picture_card.length===0");
    }
    else {
      tImg1=RowDataAt.DipchipClient[0].picture_card;
      return (
        <div style={{marginLeft:10,marginRight:10}}>
          <img width="110px" height="110px"
              src={tImg1 === "" || tImg1 === null ? "/static/media/avatar-2.cd29f3645ffb7d82bfa8.png" : "data:image/png;base64," + tImg1}
          />
          <div>card</div>
        </div>
        );
    }
    }
    catch(ex)
    {
      console.log("SHWxImgCounterServiceCard >>> catch", ex);
    }
   
  };
  const SHWxImgNDID = () => {
   let tImg=null;
    if(!RowDataAt.register_image)
    {
      return (
        <div>
          <img width="110px" height="110px"
              src={"/static/media/avatar-2.cd29f3645ffb7d82bfa8.png"}
          />
        </div>
        );
    } else {
      tImg=RowDataAt.register_image;
      return (
        <div>
          <img width="110px" height="110px"
              src={tImg === "" || tImg === null ? "/static/media/avatar-2.cd29f3645ffb7d82bfa8.png" : "data:image/png;base64," + tImg}
          />
          </div>
        );
    }
  };

  if(RegisLogType==="counter_service") {
    return(
      <Fragment>
       <div className="tag-feed-form-container">
      <div className="tag-feed-form-buttons-container">
        <button
              className="tag-detail-cancel"
              onClick={() => 
                history.push({
                    pathname:'/RegisLog_CounterService',
                    PageCurrent: PageCurrent,
                    RegisLogType:RegisLogType,
                    UISearch:UISearch
                })
              }
            >
              CANCEL
        </button>
        </div>
      <div className="acti-feed-form-inputs-container">
        <ReactJson 
          src={RowDataAt}
          theme="monokai"
          iconStyle="circle"
          style={{paddingTop:10,paddingBottom:10,paddingLeft:5
                ,fontFamily: "Lato"
                ,wordBreak:"break-all"
              }}
        />
        <div className="acti-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Image</span>
            <span style={{paddingRight:10}}>:</span>
           <span>{SHWxImgCounterServiceID()}</span> 
           <span>{SHWxImgCounterServiceCard()}</span> 
          </div>
          <div className="acti-feed-form-input-spacer" />
      </div>
      </div>
      </Fragment>
    );
  } else if (RegisLogType==="ndid") {
    return (
      <Fragment>
      <div className="tag-feed-form-container">
      <div className="tag-feed-form-buttons-container">
        <button
              className="tag-detail-cancel"
              onClick={() =>
                history.push({
                    pathname:'/RegisLog_NDID',
                    PageCurrent: PageCurrent,
                    RegisLogType:RegisLogType,
                    UISearch:UISearch
                })
              }
            >
              CANCEL
        </button> 
      </div>
      <div className="acti-feed-form-inputs-container">
      <ReactJson 
          src={RowDataAt}
          theme="monokai"
          iconStyle="circle"
          style={{paddingTop:10,paddingBottom:10,paddingLeft:5
                ,fontFamily: "Lato"
                ,wordBreak:"break-all"
              }}
        />
      <div className="acti-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Image</span>
            <span style={{paddingRight:10}}>:</span> 
            <span>{SHWxImgNDID()}</span> 
          </div>
        <div className="acti-feed-form-input-spacer" />
  
        
        
      </div>
      </div>
    </Fragment>
    );
  }

}

const withConnect = connect(
  state => ({
    ...state.regisLogDetailReducer
  }),
  {}
);

export default compose(
  withConnect,
  withRouter
)(RegisLogDetail);
