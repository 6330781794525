import React, { Fragment, useEffect, useState, useRef,useMemo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { TextInput } from "../../components/input_hook_form";
import {
  REQArticleTag,
  REQFund,
  REQContFdCrt,
  CleanConFdDetail,
  REQFundCodeList
} from "./redux/actions";
import { WarningModal, AlertModal } from "../../components/Modal";
import Select from "react-select";
import JoditEditor from "jodit-react";
import { aCategory } from "./DataInclude";
import "./style.scss";
function ConFdCrt(prp) {
  const {
    REQArticleTag,
    REQFund,
    REQContFdCrt,
    REQFundCodeList,

    ArticleTagList,
    FundList,
    history,
    FundCodeList
  } = prp;

  const GETxFundRelete = () => {
    REQArticleTag();
    REQFund();
    REQFundCodeList();
  };

  useEffect(() => {
    GETxFundRelete();
    
    return () => {
      CleanConFdDetail();
    };
  }, []);
  
  const FundCodeStrList = useMemo(() => {
    if (Array.isArray(FundCodeList)) {
      return FundCodeList.map(x => ({ value: x.fund_no, label: x.fund_code}))
    } else {
      return []
    }
  }, [FundCodeList])

  // --------------- Text Intial ---------------------
  const oNeedAuth = JSON.parse(localStorage.getItem("NeedAuth"));
  const [oSetValInit, SETxValInit] = useState({
    otbTitleEN: "",
    otbDesEN: "",
    ordUsr: "All",
    otbTitleTH: "",
    otbDesTH: "",
    coverPhoto: "",
    otbYoutubeUrl: ""
  });

  const tYoutebeCutOnSave = ptYoutube => {
    let tYoutobe = "";
    let nIndex;

    if (ptYoutube !== null || ptYoutube !== "") {
      // ----------- check v= ก่อน ------------
      if (ptYoutube.indexOf("watch?v=") > 1) {
        nIndex = ptYoutube.indexOf("watch?v=");
        nIndex = nIndex + 8;
        tYoutobe = ptYoutube.substring(nIndex, ptYoutube.length);
        
      } else if (ptYoutube.indexOf("youtu.be/") > 1) {
        nIndex = ptYoutube.indexOf("youtu.be/");
        nIndex = nIndex + 9;
        tYoutobe = ptYoutube.substring(nIndex, ptYoutube.length);
        
      } else {
        tYoutobe = ptYoutube;
      }
    } else {
      tYoutobe = ptYoutube;
    }
    return tYoutobe;
  };
  
  const SETxOnChgTxt = name => e => {
    SETxValInit({ ...oSetValInit, [name]: e.target.value });
  };

  const [oShwCrtAlr, SETxShwCrtAlr] = useState(false);
  const [oShwErrAlr, SETxShwErrAlr] = useState(false);
  const [oLangAlr, SETxLangAlr] = useState("");
  // --------------- Radio -------------
  const [oUsr, SETxUsr] = React.useState(oSetValInit.ordUsr);
  
  const [oStatus, SETxStatus] = React.useState("Publish");
  const RadioInput = ({ label, value, checked, setter }) => {
    return (
      <span>
        <input
          type="radio"
          checked={checked === value}
          onChange={() =>
            {
              setter(value);
            }
          }
        />
        <span style={{ marginLeft: 10 }}>{label}</span>
      </span>
    );
  };

  // ---------------------- Combobox Selection -----------
  

  const oArtTagOp =
    ArticleTagList.length > 0
      ? ArticleTagList.map(d => ({
          value: d.id.toString(),
          label: d.tagNameEN
        }))
      : [];

  // ------------------- Article Tag ---------------------
  const [oArtTag, SEToArtTag] = useState([]);
  const SETxOnChgSelArtTag = e => {
    SEToArtTag(Array.isArray(e) ? e.map(x => x) : []);
  };
  // ------------------- Fund ---------------------
  const [oReleteFund, SEToReleteFund] = useState([]);
  const SETxOnChgSelReleteFund = e => {
    SEToReleteFund(Array.isArray(e) ? e.map(x => x) : []);
  };
  // ------------------ Category ----------------
  const [oCategory, SEToCategory] = useState(() => {
    if (prp.location.Route === "ConFd_Invest") {
      return aCategory[0];
    } else if (prp.location.Route === "ConFd_News") {
      return aCategory[1];
    } else if (prp.location.Route === "ConFd_ContentInvest") {
      return aCategory[2];
    } else if (prp.location.Route === "ConFd_ManualInvest") {
      return aCategory[3];
    } else if (prp.location.Route === "ConFd_PVDNewsReport") {
      return aCategory[4];
    } else if (prp.location.Route === "ConFd_PVDPlaning") {
      return aCategory[5];
    } else if (prp.location.Route === "ConFd_PVDFactSheet") {
      return aCategory[6];
    } else if (prp.location.Route === "ConFd_NotifyInvest") {
      return aCategory[7];
    } else {
      return aCategory[0];
    }
  });
  const SETxOnChgSelCategory = e => {
    SEToCategory({ value: e.value, label: e.label });
  };
  // ---------------- CheckBox ---------------
  var [oHighlight] = useState([
    {
      value: 1,
      label: "Highlight",
      name: "Highlight-en",
      isCheck: false
    }
  ]);
  function SETxOnHightlight(e) {
    let nIndex = oHighlight.findIndex(
      item => e.target.value === item.value.toString()
    );
    oHighlight[nIndex].isCheck = e.target.checked;
  }

  // ------------- JoditEditor 2 ------------
  
  const editor = useRef(null);
  const [tContentEN, SETtContentEN] = useState("");
  const [tContentTH, SETtContentTH] = useState("");
  

  const oConfig = {
    readonly: false,
    height: 400
  };
  const EVTxUPDContentEN = e => {
    const tEdtTxtEn = e;
    SETtContentEN(tEdtTxtEn);
  };
  const EVTxUPDContentTH = e => {
    const tEdtTxtTh = e;
    SETtContentTH(tEdtTxtTh);
  };

  function validateContentData(oSetValInit, tContentTH, tContentEN) {
    let language = "";
    if (oSetValInit.otbTitleTH === null || oSetValInit.otbTitleTH === "") {
      language = "TH";
    } else if (oSetValInit.otbTitleEN === null || oSetValInit.otbTitleEN === "") {
      language = "EN";
    } else if (tContentTH.replace("test","") === "") {
      language = "TH";
    } else if (tContentEN.replace("test","") === "") {
      language = "EN";
    } 
    if (language !== "") {
      SETxLangAlr(language);
      SETxShwErrAlr(true);
      // Indicate validation failure
      return false;
    } 
  
    // Validation passed
    return true;
  }
  function showValidationError(language) {
    SETxLangAlr(language);
    SETxShwErrAlr(true);
    // Indicate validation failure
    return false;
  }

  return (
    <Fragment>
      <div className="conFd-feed-form-container">
        <div className="conFd-buttons-container">
          <button
            className="conFd-detail-cancel"
            onClick={() => history.goBack()}
          >
            CANCEL
          </button>
          <button
            type="submit"
            onClick={() => SETxShwCrtAlr(true)}
            className={
              (oSetValInit.otbTitleEN === "" &&
                oSetValInit.otbTitleTH === "") ||
              (oSetValInit.otbTitleEN === null &&
                oSetValInit.otbTitleTH === null)
                ? "content-feed-form-publish-button-disabled"
                : "content-feed-form-publish-button"
            }
            disabled={
              (oSetValInit.otbTitleEN === "" &&
                oSetValInit.otbTitleTH === "") ||
              (oSetValInit.otbTitleEN === null &&
                oSetValInit.otbTitleTH === null)
            }
          >
            SAVE
          </button>
          <WarningModal
            show={oShwCrtAlr}
            onHide={() => SETxShwCrtAlr(false)}
            onHandleGo={() => {
              SETxShwCrtAlr(false);

              // Validation
              if (!validateContentData(oSetValInit, tContentTH, tContentEN)) {
                return; 
              }

              // ------------------- Check Title not emptry
              
              if (oSetValInit.otbTitleTH === null || oSetValInit.otbTitleTH === "") {
                return showValidationError("TH");
              }
              if (oSetValInit.otbTitleEN === null || oSetValInit.otbTitleEN === "") { 
                return showValidationError("EN");
              }
              
              // Checks for tContentTH and tContentEN 
              if (tContentTH.replace("test","") === "") {
                return showValidationError("TH");
              }
              if (tContentEN.replace("test","") === "") {
                return showValidationError("EN");
              }

              // ------------------- Highlight ------------------
              const oHili = oHighlight.filter(CHK => CHK.isCheck === true); 
              const oHili1 = oHili.length > 0 ? oHili[0].isCheck : false; 

              // -------------------  Youtube Link -------------------
              const tYbFlt = tYoutebeCutOnSave(oSetValInit.otbYoutubeUrl); 
              const tYbFlt1 = tYbFlt !== "" ? "https://youtu.be/" + tYbFlt : ""; 

              // -------------------  Build API Data -------------------
              const oSendDataAPI = {
                // Use default if null
                imageUrl: oSetValInit.coverPhoto || "",
                // Placeholder or actual file upload logic
                fileUrl: "",
                videoUrl: tYbFlt1,
                articleTitleTh: oSetValInit.otbTitleTH,
                articleTitleEn: oSetValInit.otbTitleEN,
                shortDesTh: oSetValInit.otbDesTH,
                shortDesEn: oSetValInit.otbDesEN,
                contentTh: tContentTH || "",
                contentEn: tContentEN || "",
                showUser: oUsr,
                statusCode: oStatus === "Publish" ? 2 : 1,
                contentType: oCategory.value,
                // Short-hand condition
                isHighlight: oHili1 ? 1 : 0,
                // Placeholder or knowledge center logic
                knowledgeCenter: "",
                modifiedBy: oNeedAuth?.Email,
                tag: oArtTag.length > 0 ? oArtTag.map(a => ({tagName: a.label, tagNo: a.value})) : [{ tagName: "", tagNo: "" }],
                fund: oReleteFund.length > 0 ? oReleteFund.map(a => ({fundCode: a.label, fundNo: a.value})) : [{ fundCode: "", fundNo: "" }]
              };

              // -------------------   Make the API Request -------------------
              REQContFdCrt({
                FormData: oSendDataAPI,
                // Assuming 'history' is for navigation
                history
              }); 
            }}
            onHandleCancel={() => {
              SETxShwCrtAlr(false);
            }}
            type={"Disapprove"}
            status={"1"}
            title={"Are you sure you want to create content?"}
          />
          <AlertModal
            show={oShwErrAlr}
            onHide={() => SETxShwErrAlr(false)}
            onHandleOK={() => {
              SETxShwErrAlr(false);
            }}
            type={"Disapprove"}
            status={"1"}
            title={`Please config <strong>${
              oLangAlr === "TH" ? "THAI" : "ENGLISH"
            }</strong> version.`}
          />
        </div>
        <div className="conFd-feed-form-inputs-container">
          <div className="labelInput">
            <span className="labelInputText ">Category</span>
            <div style={{ width: 600 }}>
              <Select
                name="ocbCategory"
                options={aCategory}
                maxMenuHeight={150}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select FunTag"
                // assign onChange function
                onChange={SETxOnChgSelCategory}
                value={oCategory}
              />
            </div>
          </div>

          <div className="tag-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Cover Photo URL</span>

            <input
              className="conFd-pre-textInput"
              name="coverPhoto"
              placeholder="Enter Cover Photo URL"
              style={{ width: 475 }}
              value={oSetValInit.coverPhoto}
              onChange={SETxOnChgTxt("coverPhoto")}
            />
            <button
              type="button"
              className="srchRec-preview-button"
              onClick={() => {
                var url = document.getElementsByName("coverPhoto")[0].value;
                window.open(url, "_blank");
              }}
            >
              PREVIEW
            </button>
          </div>

          <div className="tag-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Youtube URL</span>
            <label className="conFd-pre-label">http://youtu.be/</label>
            <input
              className="conFd-pre-textInput"
              name="otbYoutubeUrl"
              style={{ width: 348 }}
              value={oSetValInit.otbYoutubeUrl}
              onChange={SETxOnChgTxt("otbYoutubeUrl")}
            />
            <button
              type="button"
              className={"srchRec-preview-button"}
              onClick={() => {
                let tUlrCode = tYoutebeCutOnSave(oSetValInit.otbYoutubeUrl);
                tUlrCode = "https://youtu.be/" + tUlrCode;
                window.open(tUlrCode, "_blank");
              }}
            >
              PREVIEW
            </button>
          </div>
          <div>
            <label className="conFd-label-warning">
              *รองรับเฉพาะลิงก์จาก Youtube เท่านั้น (กรอกเฉพาะ Code)
            </label>
          </div>

          <TextInput
            label="Title in Thai"
            placeholder="Title in Thai"
            name="otbTitleTH"
            value={oSetValInit.otbTitleTH}
            onChange={SETxOnChgTxt("otbTitleTH")}
            info={
              "Short description about the article. Content will not be shown in the app."
            }
          />
          <div className="fundrec-invalid" style={{ marginLeft: 20 }}>
            {oSetValInit.otbTitleTH !== "" ? "" : "กรุณากรอก Title in Thai"}
          </div>

          <div className="conFd-feed-form-input-spacer" />
          <TextInput
            label="Title in English"
            placeholder="Title in English"
            name="otbTitleEN"
            value={oSetValInit.otbTitleEN}
            onChange={SETxOnChgTxt("otbTitleEN")}
            info={
              "Short description about the article. Content will not be shown in the app."
            }
          />
          <div className="fundrec-invalid" style={{ marginLeft: 20 }}>
            {oSetValInit.otbTitleEN !== "" ? "" : "กรุณากรอก Title in English"}
          </div>
          <div className="conFd-feed-form-input-spacer" />
          <TextInput
            label="Short Description in Thai"
            placeholder="Short Description"
            name="otbDesTH"
            value={oSetValInit.otbDesTH}
            onChange={SETxOnChgTxt("otbDesTH")}
            info={
              "Short description about the article. Content will not be shown in the app."
            }
          />
          <div className="conFd-feed-form-input-spacer" />
          <TextInput
            label="Short Description"
            placeholder="Short Description"
            name="otbDesEN"
            value={oSetValInit.otbDesEN}
            onChange={SETxOnChgTxt("otbDesEN")}
            info={
              "Short description about the article. Content will not be shown in the app."
            }
          />

          {/*--------------------------- Article Tag -------------------------------------*/}
          <div className="conFd-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText">Article Tag</span>
            <div style={{ width: 600 }}>
              {
                <Select
                  isMulti
                  name="ocbArtiTag"
                  options={oArtTagOp}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Article Tag"
                  // assign onChange function
                  onChange={SETxOnChgSelArtTag}
                />
              }
            </div>
          </div>
          {/*----------------------------- Related Fund --------------------------------------*/}
          <div className="conFd-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Related Fund</span>
            <div style={{ width: 600 }}>
              {
                <Select
                  isMulti
                  name="ocbRelateFund"
                  options={FundCodeStrList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Related Fund"
                  // assign onChange function
                  onChange={SETxOnChgSelReleteFund}
                />
              }
            </div>
          </div>

          <div style={{ marginTop: 10 }}>
            <table border="0" className="conFd-feed-form-table">
              <tbody>
                <tr height={30}>
                  <td rowSpan="2" width={240} style={{ paddingBottom: 28 }}>
                    User
                  </td>
                  <td>
                    <RadioInput
                      className="labelInputText"
                      label="แสดงทุกกลุ่ม"
                      value="All"
                      checked={oUsr}
                      setter={SETxUsr}
                    />{" "}
                    <span style={{ marginRight: 20 }} />
                    <RadioInput
                      className="labelInputText"
                      label="เฉพาะลูกค้า PVD เท่านั้น"
                      value="PVD"
                      checked={oUsr}
                      setter={SETxUsr}
                    />{" "}
                    <span style={{ marginRight: 20 }} />
                    <RadioInput
                      label="เฉพาะลูกค้า MUTUAL FUND"
                      value="MutualFund"
                      checked={oUsr}
                      setter={SETxUsr}
                    />
                  </td>
                </tr>
                <tr height={30}>
                  <td>
                    <RadioInput
                      label="SOFT ONBOARD เท่านั้น"
                      value="SoftOnboard"
                      checked={oUsr}
                      setter={SETxUsr}
                    />{" "}
                    <span style={{ marginRight: 20 }} />
                    <RadioInput
                      label="เฉพาะลูกค้าที่มี PVD และ MUTUAL FUND"
                      value="PVDAndMutualFund"
                      checked={oUsr}
                      setter={SETxUsr}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table border="0" className="conFd-feed-form-table">
              <tbody>
                <tr height={30}>
                  <td rowSpan="2" width={240}>
                    Content Status
                  </td>
                  <td>
                    <RadioInput
                      label="Unpublished"
                      value="Unpublish"
                      checked={oStatus}
                      setter={SETxStatus}
                    />{" "}
                    <span style={{ marginRight: 20 }} />
                    <RadioInput
                      label="Published"
                      value="Publish"
                      checked={oStatus}
                      setter={SETxStatus}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/*----------------------------- Highlight --------------------------------------*/}

          <div className="labelInput" style={{ height: 40, marginTop: 10 }}>
            <span className="labelInputText ">Highlight</span>
            {oHighlight
              ? oHighlight.map((option, index) => {
                  return (
                    <Fragment key={`${option.label}${index}`}>
                      <input
                        type="checkbox"
                        id={option.label}
                        value={option.value}
                        name={option.name}
                        style={{ width: 18, height: 18, marginRight: 10 }}
                        defaultChecked={option.isCheck === true ? true : false}
                        onChange={SETxOnHightlight}
                      />
                      <label
                        htmlFor={option.label}
                        className={"checkbox-label"}
                      >
                        <label />
                        <div style={{ paddingBottom: 17, fontSize: 14 }}>
                          {option.label}
                        </div>
                      </label>
                    </Fragment>
                  );
                })
              : null}
          </div>

          {/*-----------------------------The content-------------------------------------- */}
          <div className="conFd-feed-form-input-spacer" />

          <div>
            <div className="labelInput">
              <span className="labelInputText ">The Content in Thai</span>
              <button
                type="button"
                className={"conFd-coppy-form-button"}
                style={{ width: 120, marginRight: 10 }}
              >
                COPY
              </button>
              <button
                type="button"
                className={"conFd-coppy-form-button"}
                style={{ width: 120 }}
              >
                SYNC
              </button>
            </div>
            <div className="conFd-feed-form-input-spacer" />
            <div className="fundrec-invalid" style={{ paddingLeft: 0 }}>
              {tContentTH.replace("test","") !== ""
                ? ""
                : "กรุณากรอก The Content in Thai"}
            </div>
            <JoditEditor
              ref={editor}
              value={tContentTH}
              config={oConfig}
              onBlur={EVTxUPDContentTH}
              name="EdtTH"
              onChange={() => {}}
            />
          </div>

          <div>
            <div className="labelInput">
              <span className="labelInputText ">The Content</span>
              <button
                type="button"
                className={"conFd-coppy-form-button"}
                style={{ width: 120, marginRight: 10 }}
              >
                COPY
              </button>
              <button
                type="button"
                className={"conFd-coppy-form-button"}
                style={{ width: 120 }}
              >
                SYNC
              </button>
            </div>

            <div className="conFd-feed-form-input-spacer" />
            <div className="fundrec-invalid" style={{ paddingLeft: 0 }}>
              {tContentEN.replace("test","") !== ""
                ? ""
                : "กรุณากรอก The Content in Thai"}
            </div>
            <JoditEditor
              ref={editor}
              value={tContentEN}
              config={oConfig}
              onBlur={EVTxUPDContentEN}
              name="EdtEN"
              onChange={() => {}}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.conFdDetailReducer
  }),
  {
    REQArticleTag,
    REQFund,
    REQContFdCrt,
    CleanConFdDetail,
    REQFundCodeList
  }
);

export default compose(
  withConnect,
  withRouter
)(ConFdCrt);
