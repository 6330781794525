import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQRegisDeviceList } from "./redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import { MEMRegisDeviceDetail } from "../RegisDeviceDetail/redux/actions";
import "./style.scss";
import Pagination from "./Pagination";
import Images from "../../utils/styles/images";


function RegisDeviceList(props) {
  const {
    REQRegisDeviceList,
    REQStrRegisDeviceList,
    RegisDeviceList,
    totalRegisDevice,
    MEMRegisDeviceDetail,
    history
  } = props;
  const columns = React.useMemo(() => list_of_columns, []);
  const [tSrch, SETxSrch] = useState("");
  const [nPageIndex, SETxPageIndex] = useState(0);

  const SETxPageGo = (pnPageAt) => {
    let nPageAt = pnPageAt + 1;
    SETxPageIndex(pnPageAt);
    REQRegisDeviceList({
      page: nPageAt.toString(),
      tSrch,
    });
  };
  const SRCHxRegisDevice = () => {
    SETxPageIndex(0);
    REQRegisDeviceList({
      page: "1",
      tSrch,
    });
  };

  const SRCHxUsrActClr = () => {
    SETxSrch("");
    SETxPageIndex(0);
    REQRegisDeviceList({
      page: "1",
      tSrch: "",
    });
  };

  const CALxPaging = () => {
    let nPageCnt = Math.ceil(totalRegisDevice / 10);
    return nPageCnt > 1 ? (
      <Pagination
        nPageIndex={nPageIndex}
        total={nPageCnt}
        gotoPage={SETxPageGo}
      />
    ) : null;
  };

  const GETxRegisDevice = (page, search) => {
    if (history.location.PageCurrent === undefined) {
      SETxPageIndex(0);
      REQRegisDeviceList({
        page: "1",
        tSrch,
      });
    } else {
      let nPage = history.location.PageCurrent + 1;
      SETxSrch(history.location.UISearch);
      SETxPageIndex(history.location.PageCurrent);
      REQRegisDeviceList({
        page: nPage.toString(),
        tSrch: history.location.UISearch.tSrch,
      });
    }
  };
  

  useEffect(() => {
    GETxRegisDevice();
  }, []);



  const SETxActTableClick = (cellValue , rowData, columnName) => {
        const indexRowData = rowData-1
        MEMRegisDeviceDetail({
          RowDataAt: RegisDeviceList[indexRowData],
          PageCurrent: nPageIndex
        });
        window.addEventListener("popstate", function() {
          history.push({ pathname: "/RegisDevice", PageCurrent: nPageIndex });
        });
        history.push(`/RegisDeviceDetail/${RegisDeviceList[indexRowData].id}`);
    
  };

  return (
    <Fragment>
      <div className="button-create-container" style={{ marginBottom: 1 }}>
        <div style={{ height: 40 }} />
      </div>
      {REQStrRegisDeviceList ? (
        <div className="regisDevice-list-progress">
          <CircularProgress />
          <p className="regisDevice-list-progress-text">
            {REQStrRegisDeviceList ? "Retrieving Regisdeivce list..." : null}
          </p>
        </div>
      ) : null}
      <div className="labelInput" style={{ marginLeft: 20, marginTop: 50 }}>
        <span className="labelInputText" style={{ width: 100 }}>
          Search By
        </span>
        <span style={{ paddingRight: 10 }}>:</span>
        <div
          className="usrAct-search"
          style={{ marginTop: 0, width: 331  }}
        >
          <img
            src={Images.search.search}
            alt="search-icon"
            className="transactions-list-begin-search"
            onClick={() => SRCHxRegisDevice()}
          />
          <input
            placeholder={"Search by Device Name"}
            className="usrAct-search-bar"
            value={tSrch}
            onChange={(event) => {
              SETxSrch(event.target.value.trim());
            }}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                SETxSrch(event.target.value);
                SRCHxRegisDevice();
              }
            }}
          />
          {tSrch && tSrch.length > 0 ? (
            <img
              src={Images.closeButton.close}
              alt="cancel-search"
              className="transactions-details-cancel-search"
              onClick={() => SRCHxUsrActClr()}
            />
          ) : null}
        </div>
      </div>
      
      <Table
        data={RegisDeviceList}
        columns={columns}
        handleActionClick={SETxActTableClick}
        history={history}
        pageSelect={nPageIndex}
      />
      {REQStrRegisDeviceList ? null : <CALxPaging />}
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.regisDeviceListReducer
  }),
  {
    REQRegisDeviceList,
    MEMRegisDeviceDetail
  }
);

export default compose(
  withConnect,
  withRouter
)(RegisDeviceList);
