import React, { Fragment, useState } from "react";
import {
  Tooltip,
  IconButton,
  withStyles,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Images from "../../utils/styles/images";
import { Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import "./styles.scss";
export function HeaderLabel(props) {
  const { text } = props;
  return (
    <Fragment>
      <label>
        <span className="headerLabel">{text}</span>
      </label>
    </Fragment>
  );
}

const CustomTooltip = withStyles({
  tooltip: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.57,
    letterSpacing: "0.2px",
    color: "#ffffff",
    paddingTop: "18px",
    paddingBottom: "18px",
    paddingLeft: "24px",
    paddingRight: "24px",
    backgroundColor: "#004d78",
    "&:hover": {
      backgroundColor: "transparent"
    },
    "&:focus": {
      backgroundColor: "transparent"
    },
    "&:active": {
      backgroundColor: "transparent"
    }
  }
})(Tooltip);

export function TextInput(props) {
  const {
    label,
    placeholder,
    value,
    name,
    info,
    register,
    labelStyle,
    labelTextStyle,
    infoStyle,
    inputStyle,
    disabled,
    hide,
    type,
    action = null,
    error = null,
    step = null,
    readOnly,
    onChange
  } = props;
  const labelClassName = ["labelInput", labelStyle, hide ? "hide" : ""].join(
    " "
  );
  const spanClassName = ["labelInputText", labelTextStyle].join(" ");
  const infoClassName = ["infoImage", infoStyle].join(" ");
  const inputClassName = ["textInput", inputStyle, error && "errorBorder"].join(
    " "
  );
  return (
    <Fragment>
      <label className={labelClassName}>
        {label && label.length > 0 ? (
          <span className={spanClassName}>
            {label}
            {info ? (
              <CustomTooltip title={info}>
                <IconButton aria-label="delete">
                  <img
                    src={Images.tooltipIcon.tooltip}
                    alt="info-button-img"
                    className={infoClassName}
                  />
                </IconButton>
              </CustomTooltip>
            ) : null}
          </span>
        ) : null}
        <input
          value={value}
          step={step}
          placeholder={placeholder}
          name={name}
          className={inputClassName}
          ref={register}
          disabled={disabled}
          type={type}
          onChange={onChange}
          readOnly={readOnly}
        />
        {action}
      </label>
    </Fragment>
  );
}

export function TextMultilineInput(props) {
  const {
    label,
    placeholder,
    value,
    name,
    register,
    labelStyle,
    labelTextStyle,
    inputStyle,
    hide,
    disabled,
    error = null
  } = props;
  const labelClassName = [
    "labelMultilineInput",
    labelStyle,
    hide ? "hide" : ""
  ].join(" ");
  const spanClassName = ["labelMultilineInputText", labelTextStyle].join(" ");
  const inputClassName = [
    "textMultilineInput",
    inputStyle,
    error && "errorBorder"
  ].join(" ");
  return (
    <Fragment>
      <label className={labelClassName}>
        <span className={spanClassName}>{label}</span>
        <textarea
          name={name}
          value={value}
          placeholder={placeholder}
          ref={register}
          className={inputClassName}
          disabled={disabled}
        />
      </label>
    </Fragment>
  );
}

export function DropdownInput(props) {
  const {
    label,
    placeholder,
    options,
    name,
    register,
    labelStyle,
    labelTextStyle,
    inputStyle,
    optionStyle,
    hide,
    disabled,
    error = null
  } = props;

  const labelClassName = ["labelInput", labelStyle, hide ? "hide" : ""].join(
    " "
  );
  const spanClassName = ["labelInputText", labelTextStyle].join(" ");
  const inputClassName = [
    "textDropdownInput",
    inputStyle,
    error && "errorBorder"
  ].join(" ");
  const optionClassName = ["optionText", optionStyle].join(" ");
  const optionDisabledClassName = ["optionDisabledText", optionStyle].join(" ");

  return (
    <Fragment>
      <label className={labelClassName}>
        {label && label.length > 0 ? (
          <span className={spanClassName}>{label}</span>
        ) : null}
        <select
          name={name}
          ref={register}
          className={inputClassName}
          defaultValue={""}
          disabled={disabled}
        >
          <option value="" disabled className={"placeholderValue"}>
            {placeholder}
          </option>
          {options &&
            options.map((option, index) => {
              return (
                <option
                  key={`${index}${option.value}`}
                  value={option.value}
                  className={
                    disabled ? optionDisabledClassName : optionClassName
                  }
                >
                  {option.name}
                </option>
              );
            })}
        </select>
      </label>
    </Fragment>
  );
}

export function DateInput(props) {
  const {
    hide,
    placeholder,
    label,
    labelStyle,
    labelTextStyle,
    inputStyle,
    setValue,
    name,
    defaultValues,
    control,
    disabled,
    rules,
    isIPOStart,
    isIPOEnd,
    error = null
  } = props;

  
  const labelClassName = ["labelInput", labelStyle, hide ? "hide" : ""].join(
    " "
  );
  const spanClassName = ["labelInputText", labelTextStyle].join(" ");
  const inputClassName = ["datepickerInput", inputStyle].join(" ");
  const inputDisabledClassName = [
    "datepickerInputDisabled",
    inputStyle,
    error && "datepickerInputDisabled"
  ].join(" ");
  const [tStartD, SETxStartD] = useState(defaultValues ? new Date(defaultValues) : '');
  const formatIPOStart = new Date(isIPOStart)
  const formatIPOEnd = new Date(isIPOEnd)
  const formattedStart = formatIPOStart.setHours(formatIPOStart.getHours());
  // const FinalStart = `${formattedStart.toISOString().slice(0, 19).replace("T", " ")}`;
  console.log("Start", formattedStart)
  console.log("End", formatIPOEnd)
  function returnIPOStart() {
    return (
      <div>
        <span style={{marginLeft:-300}}>
          Time:   
        </span>
        <span className="textInside" style={{marginLeft:5}}>
          08:30
        </span>
      </div>
        
    )
  }

  function returnIPOEnd() {
    return (
      <div>
        <span style={{marginLeft:-300}}>
          Time:
        </span>
        <span className="textInside" style={{marginLeft:5}} >
          15:30
        </span>
      </div>
    )
  }

  return (
    <Fragment>
      <label className={labelClassName}>
        {label && label.length > 0 ? (
          <span className={spanClassName}>{label}</span>
        ) : null}
        <div
          className={
            disabled
              ? inputDisabledClassName
              : error
              ? "datepickerInputError"
              : inputClassName
          }
        >
          <img src={Images.calendar.calendar} alt="date" />
           <Controller
            control={control}
            name={name}
            rules={rules}
            render={() => {
              return (
                <DatePicker
                  onChange={date => {
                    SETxStartD(date);
                    if (setValue) {
                      setValue(name,date);
                    }
                  }}
                  selected={tStartD}
                  valueName="selected"
                  name={name}
                  placeholderText={placeholder}
                  dateFormat="dd-MM-yyyy"
                  className="datepickerInputController"
                  disabled={disabled}
                />
                
              );
            }}
          />
        </div>
        {isIPOStart ? returnIPOStart(): null}
        {isIPOEnd ? returnIPOEnd() : null }
        
      </label>
    </Fragment>
  );
}

export function FileInput(props) {
  const {
    label,
    placeholder,
    name,
    register,
    labelStyle,
    labelTextStyle,
    hide,
    getValues,
    setValue,
    defaultValue = null,
    disabled,
    error = null
  } = props;

  const labelClassName = ["labelInput", labelStyle, hide ? "hide" : ""].join(
    " "
  );
  const spanClassName = ["labelInputText", labelTextStyle].join(" ");

  const fileHasBeenSelected =
    (getValues()[name] && getValues()[name].length > 0) || defaultValue;

  const [hasFile, setHasFile] = useState(fileHasBeenSelected);

  const determineIfRequired = () => {
    let fileFromLocal = getValues()[name];
    if (hasFile && fileFromLocal && fileFromLocal.length > 0) {
      // This value comes from user selecting a file, thus input is required
      return true;
    } else if (hasFile && defaultValue) {
      // this value comes from backend and is intended for pre-filling
      // thus input is not required
      return false;
    } else {
      // default is this input is required
      return true;
    }
  };

  const renderFilename = () => {
    let fileFromLocal = getValues()[name];
    if (hasFile && fileFromLocal && fileFromLocal.length > 0) {
      // This value comes from user selecting a file
      return <p>{fileFromLocal[0].name}</p>;
    } else if (hasFile && defaultValue) {
      // this value comes from backend and is intended for pre-filling
      // value comes in the format of /funds/image/web-wm-AdobeStock_165381596.jpg for example
      return <p>{defaultValue.replace("/funds/image/", "")}</p>;
    } else {
      return <p>{placeholder}</p>;
    }
  };

  // start test
  const docValidation = (event, defaultValue) => {
    if (!event || event.length === 0 || event[0].length === 0) {
      // No file selected or empty array
      return !!defaultValue;
    }
  
    const doc = event[0];
    const fileFormat =
      doc.type === "image/png" ||
      doc.type === "image/jpg" ||
      doc.type === "image/jpeg";
      // 5 MB
    const fileSize = 5242880;
  
    if (fileFormat && doc.size < fileSize) {
      // Valid file format and size
      return true;
    }
  
    // Invalid file format or size
    return false;
  };
  // end test

  return (
    <Fragment>
      <label className={labelClassName}>
        {label && label.length > 0 ? (
          <span className={spanClassName}>{label}</span>
        ) : null}
        <input
          ref={register({
            required: determineIfRequired(),
            validate: value => docValidation(value)
          })}
          type="file"
          name={name}
          id={name}
          className="fileinput-hidden"
          disabled={disabled}
        />

        <label
          htmlFor={name}
          className={
            disabled
              ? "fileinput-label-disabled"
              : error
              ? "fileinput-label-error"
              : "fileinput-label"
          }
          disabled={disabled}
        >
          {renderFilename()}
          {hasFile ? (
            <button
              disabled={disabled}
              onClick={() => {
                setHasFile(false);
                if (!defaultValue) {
                  // if the file is not from a pre-fill, then we need to clear it out from hook-form values
                  setValue(name, "", true);
                }
              }}
              className="fileinput-remove"
            >
              <img src={Images.closeButton.close} alt="close" />
            </button>
          ) : null}
        </label>
      </label>
    </Fragment>
  );
}

export function RadioInput(props) {
  const {
    label,
    name,
    control,
    options,
    labelStyle,
    labelTextStyle,
    radioInputStyle,
    hide,
    disabled,
    rules
  } = props;

  const labelClassName = ["labelInput", labelStyle, hide ? "hide" : ""].join(
    " "
  );
  const spanClassName = ["labelInputText", labelTextStyle].join(" ");
  const radioClassName = ["radioInput", radioInputStyle].join(" ");
  const radioDisabledClassName = ["radioDisabledInput", radioInputStyle].join(
    " "
  );

  return (
    <Fragment>
      <label className={labelClassName}>
        {label && label.length > 0 ? (
          <span className={spanClassName}>{label}</span>
        ) : null}

        <Controller
          as={
            <RadioGroup aria-label="gender">
              {options.map((option) => {
                return (
                  <FormControlLabel
                    value={option.value.toString()}
                    control={<Radio />}
                    label={option.label}
                    key={option.label}
                    disabled={disabled}
                  />
                );
              })}
            </RadioGroup>
          }
          rules={rules}
          disabled
          className={disabled ? radioDisabledClassName : radioClassName}
          name={name}
          control={control}
        />
      </label>
    </Fragment>
  );
}

export function CheckboxInput(props) {
  const {
    label,
    labelStyle,
    labelTextStyle,
    hide,
    options,
    register,
    disabled
  } = props;

  const labelClassName = ["labelInput", labelStyle, hide ? "hide" : ""].join(
    " "
  );
  const spanClassName = ["labelInputText", labelTextStyle].join(" ");

  return (
    <Fragment>
      <div className={labelClassName}>
        {label && label.length > 0 ? (
          <span className={spanClassName}>{label}</span>
        ) : null}
        {options
          ? options.map((option, index) => {
              return (
                <Fragment key={`${option.label}${index}`}>
                  <input
                    type="checkbox"
                    id={option.label}
                    value={option.value}
                    name={option.name}
                    ref={register}
                    style={{width:18,height:18,marginRight:10}}            
                    className={
                     disabled ? "checkbox-input-disabled" : ""
                    }
                    disabled={disabled}
                  />
                  <label
                    htmlFor={option.label}
                    className={"checkbox-label"}
                    disabled={disabled}
                  >
                    <label />
                    <p>{option.label}</p>
                  </label>
                </Fragment>
              );
            })
          : null}
      </div>
    </Fragment>
  );
}

export function SelectInput(props) {
  const {
    label,
    name,
    control,
    labelStyle,
    labelTextStyle,
    selectInputStyle,
    hide,
    options,
    placeholder,
    disabled,
    rules,
    error = null
  } = props;

  const labelClassName = ["labelInput", labelStyle, hide ? "hide" : ""].join(
    " "
  );
  const spanClassName = ["labelInputText", labelTextStyle].join(" ");
  const selectContainerClassName = [
    "selectInputContainer",
    selectInputStyle
  ].join(" ");

  return (
    <Fragment>
      <label className={labelClassName}>
        {label && label.length > 0 ? (
          <span className={spanClassName}>{label}</span>
        ) : null}

        <Controller
          as={<CreatableSelect options={options} isMulti isClearable />}
          control={control}
          rules={rules}
          onChange={([selected]) => {
            // Place your logic here
            return selected;
          }}
          name={name}
          // defaultValue={{ value: "" }}
          isDisabled={disabled}
          components={{ DropdownIndicator: () => null, Menu: () => null }}
          className={selectContainerClassName}
          classNamePrefix={error ? "select-input-error" : "select-input"}
          placeholder={placeholder}
        />
      </label>
    </Fragment>
  );
}
