import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQUntHld } from "./redux/actions";
import Table from "./table";
import { aColUntHld } from "./list_of_columns";
import Images from "../../utils/styles/images";
import "./style.scss";
import Pagination from "./Pagination";
function AnalyticUntHldDetail(props) {
  const {
    REQUntHld,
    REQStrUntHldList,
    UntHldList,
    UISearch,
    totalUntHldList,
    history
  } = props;
  const tColumeUntHld = React.useMemo(() => aColUntHld, []);
  const [tSrch, SETxSrch] = useState("");
  const [tOpSrch, SETxOpSrch] = React.useState("Email");
  const [nPageIndex, SETxPageIndex] = useState(0);
  const [tSortH, SETtSortH] = useState("creUpdatedAt DESC");

  const CALLxAnalyticUntHldDetail = () => {
    if (history.location.PageCurrent === undefined) {
      SETxPageIndex(0);
      REQUntHld({
        page: "1",
        tAccStatusCodeType: history.location.AccStatusCodeType,
        tStartD: history.location.UISearch.tStartD,
        tEndD: history.location.UISearch.tEndD,
        bAllD: history.location.UISearch.bAllD,
        tSrch,
        tOpSrch,
        tSortH
      });
    } else {
      let nPage = history.location.PageCurrent + 1;
      if (history.location.pathname === "/Analytic_Apps") {
        SETxOpSrch(history.location.UISearch.tOpSrch);
        SETxSrch(history.location.UISearch.tSrch);

        SETxPageIndex(history.location.PageCurrent);
        REQUntHld({
          page: nPage.toString(),
          tAccStatusCodeType: history.location.AccStatusCodeType,
          tStartD: history.location.UISearch.tStartD,
          tEndD: history.location.UISearch.tEndD,
          bAllD: history.location.UISearch.bAllD,
          tSrch: history.location.UISearch.tSrch,
          tOpSrch: history.location.UISearch.tOpSrch,
          tSortH: history.location.UISearch.tSortH
        });
      }
    }
  };

  const SRCHxEasyInvAct = () => {
    SETxPageIndex(0);
    REQUntHld({
      page: "1",
      tAccStatusCodeType: history.location.AccStatusCodeType,
      tStartD: history.location.UISearch.tStartD,
      tEndD: history.location.UISearch.tEndD,
      bAllD: history.location.UISearch.bAllD,
      tSrch,
      tOpSrch,
      tSortH
    });
  };

  const SRCHxEasyInvActClr = () => {
    SETxSrch("");
    SETxPageIndex(0);
    REQUntHld({
      page: "1",
      tAccStatusCodeType: history.location.AccStatusCodeType,
      tStartD: history.location.UISearch.tStartD,
      tEndD: history.location.UISearch.tEndD,
      bAllD: history.location.UISearch.bAllD,
      tSrch: "",
      tOpSrch,
      tSortH
    });
  };

  const RadioInput = ({ label, value, checked, setter }) => {
    return (
      <span>
        <input
          type="radio"
          checked={checked === value}
          onChange={() =>
            {
              setter(value);
            }
          }
        />
        <span style={{ marginLeft: 0 }}>{label}</span>
      </span>
    );
  };

  // start test pass
  const columnSortDirections = {
    "REGISTER DATE": ["created_at ASC", "created_at DESC"],
    "USERNAME": ["username ASC", "username DESC"],
    "E-MAIL": ["email ASC", "email DESC"],
    "ACCOUNT TYPE": ["hasConverted ASC", "hasConverted DESC"],
    "UNITHOLDER": ["UnitCount ASC", "UnitCount DESC"]
  };
  
  const SETxActHeaderClick = (cell) => {
    const sortDirections = columnSortDirections[cell];
    if (sortDirections) {
      const currentSort = tSortH;
      const newSort = sortDirections.find((direction) => direction !== currentSort) || sortDirections[0];
      SETtSortH(newSort);
    }
  };
  // end test

  const SETxActTableClick = (cellValue, rowData, columnName) => {
    if (columnName === "EMAIL") {
      console.log('SETxActTableClick > columnName === "EMAIL"');
    }
  };

  const SETxPageGo = pnPageAt => {
    let nPageAt = pnPageAt + 1;
    
    SETxPageIndex(pnPageAt);
    REQUntHld({
      page: nPageAt.toString(),
      tAccStatusCodeType: history.location.AccStatusCodeType,
      tStartD: history.location.UISearch.tStartD,
      tEndD: history.location.UISearch.tEndD,
      bAllD: history.location.UISearch.bAllD,
      tSrch,
      tOpSrch,
      tSortH
    });
    
  };

  const CALxPaging = () => {
    let nPageCnt = Math.ceil(totalUntHldList / 10);
    return nPageCnt > 1 ? (
      <Pagination
        nPageIndex={nPageIndex}
        total={nPageCnt}
        gotoPage={SETxPageGo}
      />
    ) : null;
  };

  const GETxWidthSrch = () => {
    if (tOpSrch === "UUID") {
      return 450;
    } else {
      return 331;
    }
  };

  useEffect(() => {
    CALLxAnalyticUntHldDetail();
    return () => {
      
      if (history.action === "POP") {
        history.replace({ pathname: "/Analytic_Apps", UISearch: UISearch });
      }
    };
  }, [tSortH]);
  
  return (
    <Fragment>
      <div className="button-create-container" style={{ marginBottom: 10 }}>
        <div style={{ height: 40 }} />
        {REQStrUntHldList ? (
          <div className="regisDevice-list-progress">
            <CircularProgress />
            <p className="regisDevice-list-progress-text">
              {REQStrUntHldList ? "Retrieving UntHld list..." : null}
            </p>
          </div>
        ) : null}
      </div>

      <div className="labelInput" style={{ marginLeft: 20, marginTop: 50 }}>
        <span className="labelInputText" style={{ width: 100 }}>
          Search By
        </span>
        <span style={{ paddingRight: 10 }}>:</span>
        <div
          className="usrAct-search"
          style={{ marginTop: 0, width: GETxWidthSrch() }}
        >
          <img
            src={Images.search.search}
            alt="search-icon"
            className="transactions-list-begin-search"
            onClick={() => SRCHxEasyInvAct()}
          />
          <input
            placeholder={"Search by " + tOpSrch}
            className="usrAct-search-bar"
            value={tSrch}
            onChange={event => {
              SETxSrch(event.target.value);
            }}
            onKeyDown={event => {
              
              if (event.keyCode === 13) {
                SETxSrch(event.target.value);
                SRCHxEasyInvAct();
              }
            }}
          />
          {tSrch && tSrch.length > 0 ? (
            <img
              src={Images.closeButton.close}
              alt="cancel-search"
              className="transactions-details-cancel-search"
              onClick={() => SRCHxEasyInvActClr()}
            />
          ) : null}
        </div>
      </div>
      <div
        className="clients-radio-container"
        style={{ marginLeft: 145, marginBottom: 20 }}
      >
        <RadioInput
          label="E-mail"
          value="Email"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="Username"
          value="Username"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="English Name"
          value="English Name"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="Thai Name"
          value="Thai Name"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="ID No. / Passport No."
          value="ID No. / Passport No."
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="Mobile"
          value="Mobile"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
      </div>
      <button
        style={{ marginLeft: 20, marginBottom: 20 }}
        onClick={() => {
          SRCHxEasyInvAct();
        }}
        className="conFd-synce-button"
      >
        Search
      </button>
      {}
      {REQStrUntHldList ? null : (
        <Table
          columns={tColumeUntHld}
          data={UntHldList === null ? [] : UntHldList}
          handleActionHeaderClick={SETxActHeaderClick}
          handleActionClick={SETxActTableClick}
          pageSelect={nPageIndex}
        />
      )}
      {REQStrUntHldList ? null : <CALxPaging />}
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.analyticDetailReducer
  }),
  {
    REQUntHld
  }
);

export default compose(
  withConnect,
  withRouter
)(AnalyticUntHldDetail);
