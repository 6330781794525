import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQAnalyticList } from "./redux/actions";
import DateInput from "./DateInput";
import "./style.scss";
function AnalyticList(props) {
  const {
    REQAnalyticList,
    REQStrAnalyticList,
    AnalyticList,
    history
  } = props;

  const [tStartD, SETxStartD] = useState(new Date());
  const [tEndD, SETxEndD] = useState(new Date());
  const [bAllD, SETbAllD] = useState(false);

  const CALLxAnalytic = () => {
    if (history.location.UISearch === undefined) {
      REQAnalyticList({
        tStartD,
        tEndD,
        bAllD
      });
    } else {
      SETxStartD(new Date(history.location.UISearch.tStartD));
      SETxEndD(new Date(history.location.UISearch.tEndD));

      SETbAllD(history.location.UISearch.bAllD);

      REQAnalyticList({
        tStartD: history.location.UISearch.tStartD,
        tEndD: history.location.UISearch.tEndD,
        bAllD: history.location.UISearch.bAllD
      });
    }
  };

  const SRCHxAnalyticAct = () => {
    REQAnalyticList({
      tStartD,
      tEndD,
      bAllD
    });
  };

  const SETxChangeHighlight = () => {
    REQAnalyticList({
      tStartD,
      tEndD,
      bAllD: !bAllD
    });

    SETbAllD(!bAllD);
  };

  const SETxActTableClick = ptEasyInvestType => {
    history.push({
      pathname: "/AnalyticDetail",
      EasyInvestType: ptEasyInvestType,
      UISearch: {
        tStartD: tStartD,
        tEndD: tEndD,
        bAllD: bAllD
      }
    });
  };

  const CALxTableAnaly = (poData) => {
    let oData = poData.data;
    return (
      <div className="usrAct-list-container">
        <h3
          style={{
            textAlign: "center",
            color: "#000000",
            fontWeight: "bold",
            fontFamily: "Lato"
          }}
        >
          E@syinvest conversion
        </h3>
        <div className="acti-feed-form-input-spacer" />
        <table className="usrAct-table" role="table">
          <thead className="usrAct-head-container">
            <tr role="row" className="usrAct-head-row">
              <th
                colSpan={1}
                role="columnheader"
                className="usrAct-header"
                style={{ width: 100, paddingLeft: 10 }}
              >
                Login
              </th>
              <th
                colSpan={1}
                role="columnheader"
                className="usrAct-header"
                style={{ width: 100, paddingLeft: 10 }}
              >
                Didn't pass convert
              </th>
              <th
                colSpan={1}
                role="columnheader"
                className="usrAct-header"
                style={{ width: 100, paddingLeft: 10 }}
              >
                Pass convert
              </th>
              <th
                colSpan={1}
                role="columnheader"
                className="usrAct-header"
                style={{ width: 100, paddingLeft: 10 }}
              >
                Open Mutual Fund
              </th>
            </tr>
          </thead>
          <tbody className="usrAct-table-body">
            <tr role="row" className="usrAct-table-row">
              <td
                role="cell"
                className="usrAct-cell"
                style={{
                  width: 100,
                  paddingLeft: 10,
                  textAlign: "left"
                }}
              >
                {oData.LoginTotal === 0 ? (
                  oData.LoginTotal
                ) : (
                  <button
                    className="feed-list-article-button"
                    onClick={() => SETxActTableClick("1")}
                  >
                    {oData.LoginTotal}
                  </button>
                )}
              </td>
              <td
                role="cell"
                className="usrAct-cell"
                style={{
                  width: 100,
                  paddingLeft: 10,
                  textAlign: "left"
                }}
              >
                {oData.DoNotPassConvertTotal === 0 ? (
                  oData.DoNotPassConvertTotal
                ) : (
                  <button
                    className="feed-list-article-button"
                    onClick={() => SETxActTableClick("2")}
                  >
                    {oData.DoNotPassConvertTotal}
                  </button>
                )}
              </td>
              <td
                role="cell"
                className="usrAct-cell"
                style={{
                  width: 100,
                  paddingLeft: 10,
                  textAlign: "left"
                }}
              >
                {oData.PassConvertTotal === 0 ? (
                  oData.PassConvertTotal
                ) : (
                  <button
                    className="feed-list-article-button"
                    onClick={() => SETxActTableClick("3")}
                  >
                    {oData.PassConvertTotal}
                  </button>
                )}
              </td>
              <td
                role="cell"
                className="usrAct-cell"
                style={{
                  width: 100,
                  paddingLeft: 10,
                  textAlign: "left"
                }}
              >
                {oData.OpenMFTotal === 0 ? (
                  oData.OpenMFTotal
                ) : (
                  <button
                    className="feed-list-article-button"
                    onClick={() => SETxActTableClick("4")}
                  >
                    {oData.OpenMFTotal}
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    CALLxAnalytic();
  }, []);

  return (
    <Fragment>
      <div className="button-create-container" style={{ marginBottom: 1 }}>
        <div style={{ height: 40 }} />
        {REQStrAnalyticList ? (
          <div className="regisDevice-list-progress">
            <CircularProgress />
            <p className="regisDevice-list-progress-text">
              {REQStrAnalyticList ? "Retrieving Analytic list..." : null}
            </p>
          </div>
        ) : null}
      </div>

      <div
        className="date-range-container"
        style={{ width: 400, marginTop: 50 }}
      >
        <p className="labelInputText" style={{ width: 290 }}>
          Date
          <span style={{ color: "#FF0000" }}>*</span>
        </p>
        <p style={{ paddingLeft: 65, paddingRight: 10 }}>:</p>

        <DatePicker
          selected={tStartD}
          onChange={date => SETxStartD(date)}
          selectsStart
          startDate={tStartD}
          endDate={tEndD}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
          className={
            bAllD === false ? "date-range-input" : "date-range-input-disable"
          }
          disabled={bAllD}
        />
        <p className="date-range-separator">-</p>
        {
          <DatePicker
            selected={tEndD}
            onChange={date => moment(date).isAfter(tStartD) && SETxEndD(date)}
            selectsEnd
            startDate={tStartD}
            endDate={tEndD}
            dateFormat="dd/MM/yy"
            customInput={<DateInput />}
            className={
              bAllD === false ? "date-range-input" : "date-range-input-disable"
            }
            disabled={bAllD}
          />
        }
        <div className="labelInput" style={{ height: 40, marginLeft: 20 }}>
          <input
            style={{ width: 18, height: 18, marginRight: 10 }}
            type="checkbox"
            id="Highlight"
            name="Highlight"
            checked={bAllD}
            onChange={SETxChangeHighlight}
          />
          <span className="labelInputText ">Show all date</span>
        </div>
      </div>

      <button
        style={{ marginLeft: 20, marginBottom: 20 }}
        onClick={() => {
          SRCHxAnalyticAct();
        }}
        className="conFd-synce-button"
      >
        Search
      </button>
      {}
      {REQStrAnalyticList ? null : <CALxTableAnaly data={AnalyticList} />}
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.analyticListReducer
  }),
  {
    REQAnalyticList
  }
);

export default compose(
  withConnect,
  withRouter
)(AnalyticList);
